import * as React from "react"
import {graphql} from "gatsby";
import { render } from 'datocms-structured-text-to-html-string';

import StaticPageTemplate from "./../components/staticPageTemplate"

import SEO from './../components/SEO';

export const getBurial = graphql`
query MyQuery {
  datoCmsBurial {
    body {
      value
    }
    title
  }
}
`

const BurialPage = ({data}) => {
  return (
    <StaticPageTemplate title={data.datoCmsBurial.title}>
      <SEO title="Miejsce Pamięci Dzieci Utraconych w Dębicy - o pochówku" link="https://debica-dzieciutracone.pl/o-pochowku" ogTitle="O pochówku - Miejsce Pamięci Dzieci Utraconych w Dębicy" description="Pochówek odbywa się dwa razy w roku: w pierwszą niedzielę po Dniu Świętości Życia(25 marca) oraz w niedzielę po wspomnieniu Dziecka Utraconego(15 październik)."/>

       <div className="DatoCMS" dangerouslySetInnerHTML={{__html: render(data.datoCmsBurial.body.value)}}></div>
    </StaticPageTemplate>
  )
}

export default BurialPage